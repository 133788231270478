import { generateListByMap } from '@/utils'

export const qaStatusMap = {
  0: 'Unchecked',
  1: 'Checked',
  2: 'Overdue'
}

export const { list: qaStatusList, enum: qaStatusEnum } = generateListByMap(qaStatusMap)

export const platformList = [
  {
    label: 'Amazon',
    value: 'Amazon'
  },
  {
    label: 'Walmart',
    value: 'Walmart'
  },
  {
    label: 'eBay',
    value: 'eBay'
  },
  {
    label: 'StandWebsite',
    value: 'StandWebsite'
  }
]
