<template>
  <div>
    <el-form inline size="small">
      <el-form-item label="Ticket Number">
        <el-input
          v-model="form.workNumber"
          clearable
          placeholder="Input"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="Select Time">
        <el-date-picker
          v-model="form.date"
          :editable="false"
          :picker-options="{firstDayOfWeek: 1}"
          align="center"
          end-placeholder="End"
          range-separator="~"
          start-placeholder="Start"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="Platform">
        <el-select
          v-model="form.platform"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in platformList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="QA Status">
        <el-select
          v-model="form.qaStatus"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in qaStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Score Nature">
        <el-select
          v-model="form.scoreNature"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in scoreNatureList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Suggested Reply">
        <el-select
          v-model="form.suggestedReply"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option :value="0" label="Suggested" />
          <el-option :value="1" label="No" />
        </el-select>
      </el-form-item>
      <el-form-item label="Best Case">
        <el-select
          v-model="form.bestCase"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option :value="0" label="YES" />
          <el-option :value="1" label="NO" />
        </el-select>
      </el-form-item>
      <el-form-item label="QA Agent">
        <el-select
          v-model="form.qaAgent"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in qaList"
            :key="item.agent + item.group"
            :label="item.agent"
            :value="item.agent"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Responsible Agent">
        <el-select
          v-model="form.agent"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in customerServiceList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Team">
        <el-select
          v-model="form.team"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in teamList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          Search
        </el-button>
        <el-button @click="handleReset">Reset</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button size="small" @click="handleExport">
          Export Query Results
        </el-button>
        <el-button
          v-if="isQA || isCustomerService"
          :loading="downloadLoading"
          size="small"
          type="primary"
          @click="handleDownload"
        >
          Download QA Rules
        </el-button>
        <el-button
          v-if="isTeamLeader"
          size="small"
          type="primary"
          @click="uploadQARulesDialogVisible = true"
        >
          Update QA Rules
        </el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :edit-config="{ showIcon:false }"
      :loading="queryLoading"
      :max-height="maxHeight"
      :row-class-name="({row}) => ({ 'best-case': +row.bestCase === 0, 'unqualified': row.scoreNature === 'Unqualified' })"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column :formatter="emptyCellFormatter" field="workNumber" title="Ticket Number" width="120" />
      <vxe-column :edit-render="{}" field="responsibleAgent" title="Responsible Agent" width="185">
        <template v-slot="{row}">
          <div style="display: flex;align-items: center;flex-wrap: nowrap;justify-content: center">
            <span>{{ row.agent }}</span>
            <i
              v-if="isQA"
              class="el-icon-edit-outline"
              style="cursor: pointer;color: #409EFF;font-size: 24px;width: 40px;"
              @click="handleEditAgent(row)"
            />
          </div>
        </template>
        <template #edit="{row}">
          <div style="display: flex;align-items: center;flex-wrap: nowrap;justify-content: center">
            <el-select
              v-model="changedAgent"
              filterable
              no-data-text="No Data"
              no-match-text="No matching data"
              placeholder="Please select"
              popper-class="vxe-table--ignore-clear"
              style="width: 120px"
            >
              <el-option v-for="item in (row.agents ? row.agents.split(',') : [])" :key="item" :label="item" :value="item" />
            </el-select>
            <i
              class="el-icon-check"
              style="cursor: pointer;color: #409EFF;font-size: 24px;width: 40px;"
              @click="handleChangeResponsibleAgent(row)"
            />
          </div>
        </template>
      </vxe-column>
      <vxe-column :formatter="emptyCellFormatter" field="team" min-width="100" title="Team" />
      <vxe-column :formatter="emptyCellFormatter" field="qaStatus" title="QA Status" width="90">
        <template v-slot="{row}">
          {{ qaStatusMap[row.qaStatus] }}
        </template>
      </vxe-column>
      <vxe-column :formatter="emptyCellFormatter" field="scoreNature" title="Score Nature" width="110" />
      <vxe-column :formatter="emptyCellFormatter" field="score" sort-type="number" sortable title="Score" width="80" />
      <vxe-column :formatter="emptyCellFormatter" title="Best Case" width="60">
        <template v-slot="{row}">
          {{ +row.bestCase === 0 ? 'YES' : +row.bestCase === 1 ? 'NO' : row.bestCase || '/' }}
        </template>
      </vxe-column>
      <vxe-column :formatter="emptyCellFormatter" title="Suggested Reply" width="95">
        <template v-slot="{row}">
          {{ +row.suggestedReply === 0 ? 'Suggested' : +row.suggestedReply === 1 ? 'No' : row.suggestedReply || '/' }}
        </template>
      </vxe-column>
      <vxe-column :formatter="emptyCellFormatter" field="escalationStatus" title="Escalation Status" width="135" />
      <vxe-column :formatter="emptyCellFormatter" field="qaAgent" title="QA Agent" width="100" />
      <vxe-column min-width="160" title="Operation">
        <template v-slot="{row}">
          <el-button type="text" @click="handleJumpDetail(row)">
            Ticket detail
          </el-button>
          <!--质检状态 = 已质检 && 之前处理人=当前登录用户 => 修改-->
          <el-button
            v-if="isShowModify(row)"
            type="text"
            @click="handleOpenScoreDialog('score', row)"
          >
            Modify QA Detail
          </el-button>
          <!--质检状态 = 未质检 && 客服 != 当前登录用户(因为可能兼任客服和QA)-->
          <el-button
            v-if="isShowScore(row)"
            type="text"
            @click="handleOpenScoreDialog('score', row)"
          >
            Score
          </el-button>
          <el-button
            v-if="isCustomerService || isTeamLeader"
            :disabled="!(+row.qaStatus === +qaStatusEnum['Checked'])"
            type="text"
            @click="handleOpenScoreDialog('detail', row)"
          >
            QA Detail
          </el-button>
          <!--客服本人 && 质检状态 = 已质检 && 申诉状态 = 未申诉-->
          <el-button
            v-if="isShowEscalate(row)"
            type="text"
            @click="handleOpenEscalateDialog(row)"
          >
            Escalate
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <upload-q-a-rules-dialog :visible.sync="uploadQARulesDialogVisible" />
    <score-dialog
      :row.sync="currentRow"
      :type="scoreType"
      :visible.sync="scoreDialogVisible"
      @refresh="handleQuery"
    />
    <escalate-dialog
      :row.sync="currentRow"
      :visible.sync="escalateDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import UploadQARulesDialog from './UploadQARulesDialog.vue'
import ScoreDialog from './ScoreDialog.vue'
import EscalateDialog from './EscalateDialog.vue'
import {
  getCustomerServiceList,
  getQualityInspectionList,
  getQualityInspectionRulesLatestFile,
  updateAgent
} from './apis/list'
import { platformList, qaStatusEnum, qaStatusList, qaStatusMap } from './const'
import { roleEnum, scoreNatureList, teamList } from '../const'
import qs from 'qs'
import dayjs from 'dayjs'
import { getQualityAgentList } from '../customer-service/apis/list'
import { statusEnum as roleStatusEnum } from '../customer-service/const'

require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

export default {
  name: 'WorkOrderQualityInspectionList',
  components: {
    EscalateDialog,
    ScoreDialog,
    UploadQARulesDialog,
    Paging
  },
  data() {
    return {
      changedAgent: null,
      qaList: [],
      teamLeaderList: [],
      customerServiceList: [],
      form: {
        suggestedReply: '',
        bestCase: '',
        workNumber: '',
        agent: [],
        team: '',
        qaStatus: '',
        scoreNature: [],
        date: [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        platform: [],
        qaAgent: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      downloadLoading: false,
      maxHeight: 0,
      tableData: [],
      currentRow: null,
      uploadQARulesDialogVisible: false,
      scoreDialogVisible: false,
      escalateDialogVisible: false,
      scoreType: ''
    }
  },
  computed: {
    qaStatusMap() {
      return qaStatusMap
    },
    qaStatusEnum() {
      return qaStatusEnum
    },
    username() {
      return this.$store.state.user.name
    },
    isCustomerService() {
      // 忽略大小写
      return this.customerServiceList.map(e => e.toLowerCase()).includes(this.username.toLowerCase())
    },
    isQA() {
      // 忽略大小写
      return this.qaList.map(e => e.agent.toLowerCase()).includes(this.username.toLowerCase())
    },
    isTeamLeader() {
      // 忽略大小写
      return this.teamLeaderList.map(e => e.agent.toLowerCase()).includes(this.username.toLowerCase())
    },
    formParams() {
      const [createTimeStart, createTimeEnd] = this.form.date || []
      return {
        ...this.form,
        date: undefined,
        createTimeEnd,
        createTimeStart
      }
    },
    queryParams() {
      return {
        ...this.formParams,
        current: this.pager.current,
        size: this.pager.size,
        workNumber: this.form.workNumber.trim(),
        agent: this.form.agent.join(','),
        scoreNature: this.form.scoreNature.join(','),
        platform: this.form.platform.join(',')
      }
    },
    scoreNatureList() {
      return scoreNatureList
    },
    platformList() {
      return platformList
    },
    teamList() {
      return teamList
    },
    qaStatusList() {
      return qaStatusList
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.getCustomerServiceList()
    this.getRoleList()
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getRoleList() {
      const { datas } = await getQualityAgentList({
        delFlag: roleStatusEnum['启用'],
        current: 1,
        size: 1000
      })
      const { records } = datas || {}
      if (records?.length) {
        this.teamLeaderList = records.filter(item => item.role === roleEnum['TeamLeader']).filter((e, i, a) => {
          // 根据 agent 去重
          return a.findIndex(item => item.agent === e.agent) === i
        })
        this.qaList = records.filter(item => item.role === roleEnum['质检员']).filter((e, i, a) => {
          // 根据 agent 去重
          return a.findIndex(item => item.agent === e.agent) === i
        })
      }
    },
    emptyCellFormatter({ row, column }) {
      return row[column.property] || '/'
    },
    async getCustomerServiceList() {
      const { datas } = await getCustomerServiceList()
      this.customerServiceList = datas || []
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getQualityInspectionList(this.queryParams, signal).finally(() => {
        this.queryLoading = this.queryChange = false
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleReset() {
      this.form = {
        workNumber: '',
        agent: [],
        team: '',
        qaStatus: '',
        scoreNature: [],
        date: [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        platform: [],
        qaAgent: ''
      }
      this.handleQuery()
    },
    handleExport() {
      window.open(`${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/exportQuality?${qs.stringify(this.formParams)}`)
    },
    async handleDownload() {
      this.downloadLoading = true
      const { datas } = await getQualityInspectionRulesLatestFile().finally(() => {
        this.downloadLoading = false
      })
      if (datas?.fileUrl) {
        window.open(datas.fileUrl)
      } else {
        this.$message.info('No QA rules found')
      }
    },
    handleEditAgent(row) {
      this.changedAgent = row.agent
      this.$refs.table.setActiveCell(row, 'responsibleAgent')
    },
    async handleChangeResponsibleAgent(row) {
      const { msg } = await updateAgent({
        id: row.id,
        agent: this.changedAgent
      })
      this.changedAgent = null
      this.$message.success(msg)
      await this.$refs.table.clearActived()
      await this.handleQuery()
    },
    handleJumpDetail(row) {
      const router = this.$router.resolve({
        path: '/service-manage/Buyer-mail-message/message-reply',
        query: {
          id: row.messageId,
          disabled: 'true'
        }
      })
      window.open(router.href, '_blank')
    },
    handleOpenScoreDialog(type, row) {
      this.currentRow = row
      this.scoreType = type
      this.scoreDialogVisible = true
    },
    isShowModify(row) {
      return this.isQA && row.qaAgent?.toLowerCase() === this.username.toLowerCase() && +row.qaStatus === +qaStatusEnum['Checked']
    },
    isShowScore(row) {
      return this.isQA && row.agent?.toLowerCase() !== this.username.toLowerCase() && +row.qaStatus === +qaStatusEnum['Unchecked']
    },
    isShowEscalate(row) {
      // return true
      return +row.qaStatus === +qaStatusEnum['Checked'] && row.escalationStatus === 'Unescalation' && row.agent?.toLowerCase() === this.username?.toLowerCase()
    },
    handleOpenEscalateDialog(row) {
      this.currentRow = row
      this.escalateDialogVisible = true
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss">
.unqualified {
  color: #F56C6C;
}

.best-case {
  color: #67C23A;
}
</style>
