<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    center
    title="Escalate"
    top="5vh"
    width="80%"
  >
    <h4>
      Please input the reason for escalation (Limit 2000 characters)
    </h4>
    <rich-text ref="reason" v-model="reason" :height="300" />
    <el-descriptions>
      <el-descriptions-item label="Characters count">
        {{ getCharactersCount() }}
      </el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <el-button @click="handleClose">Cancel</el-button>
      <el-button type="primary" @click="handleSubmit">Submit</el-button>
    </template>
  </el-dialog>
</template>

<script>
import RichText from '@/components/RichText/index.vue'
import { sendEscalation } from './apis/escalateDialog'

export default {
  name: 'EscalateDialog',
  components: {
    RichText
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      reason: ''
    }
  },
  methods: {
    handleClose() {
      this.reason = ''
      this.$refs.reason.setContent('')
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    getCharactersCount() {
      return this.$refs.reason?.getHtmlStrCharLength(this.reason) || 0
    },
    handleSubmit() {
      if (!this.reason) {
        this.$message.warning('Please input the reason for escalation')
        return
      }
      if (this.getCharactersCount() > 2000) {
        this.$message.warning('The reason for escalation cannot exceed 2000 characters')
        return
      }
      this.$confirm('Are you sure to escalate?', 'Escalate', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg } = await sendEscalation({
              ...this.row,
              qualityId: this.row.id,
              reason: this.reason
            }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            this.$message.success(msg || 'Escalate successfully')
            this.$emit('refresh')
            done()
            this.handleClose()
          } else {
            done()
          }
        }
      }).catch(() => { })
    }
  }
}
</script>

<style scoped>

</style>
