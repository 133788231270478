<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    center
    title="QA Rules"
    top="5vh"
    width="500px"
  >
    <h4>
      Upload new version
    </h4>
    <upload
      ref="upload"
      v-model="file"
      :accept="null"
      :action="action"
      :limit="1"
      drag
      list-type="text"
      @remove="handleRemove"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">Drag the file to here，or&nbsp;<em>click on Upload</em></div>
    </upload>
    <div style="width: 100%;text-align: center;margin-top: 10px">
      <el-button @click="handleClose">Cancel</el-button>
      <el-button type="primary" @click="handleSubmit">Upload</el-button>
    </div>
    <h4>
      Previous Version
    </h4>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      resizable
    >
      <vxe-column field="version" title="Version" />
      <vxe-column field="createByName" title="Uploader" />
      <vxe-column field="createTime" title="Upload Date" width="160" />
      <vxe-column>
        <template #default="{row}">
          <el-link :href="row.fileUrl" :underline="false" target="_blank" type="primary">
            Download
          </el-link>
        </template>
      </vxe-column>
    </vxe-table>
  </el-dialog>
</template>

<script>
import Upload from '@/components/Upload/index.vue'
import { getQualityInspectionRulesFileList } from './apis/list'
import { uploadQARules } from './apis/uploadQARulesDialog'
import { debounceGetTableMaxHeight } from '@/utils'

export default {
  name: 'UploadQARulesDialog',
  components: {
    Upload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      action: process.env['VUE_APP_UPLOAD_API'],
      queryLoading: false,
      maxHeight: 0,
      file: [],
      tableData: []
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.debounceGetTableMaxHeight()
        this.getRulesList()
      }
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      minHeight: 300
    })
  },
  mounted() {
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getRulesList() {
      this.queryLoading = true
      const { datas } = await getQualityInspectionRulesFileList().finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas || []
    },
    handleRemove() {
      this.$refs.upload.clearFiles()
    },
    handleClose() {
      this.file = []
      this.$refs.upload.clearFiles()
      this.tableData = []
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      if (!this.file.length) {
        this.$message('Please upload the file')
        return
      }
      this.$confirm('Are you sure to upload the file?', 'Confirm', {
        type: 'warning',
        closeOnClickModal: false,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.cancelButtonLoading = instance.confirmButtonLoading = true
            const { msg } = await uploadQARules({
              fileType: 0,
              fileUrl: this.file[0].signatureUrl
            }).finally(() => {
              instance.cancelButtonLoading = instance.confirmButtonLoading = false
            })
            this.$message.success(msg)
            this.handleClose()
            done()
          } else {
            done()
          }
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 10px 20px;
}

::v-deep .el-upload, ::v-deep .el-upload-dragger {
    width: 100%;
}
</style>
